.calendar-date {
  margin-bottom: 0.8em;
}

.calendar-hour {
  margin-bottom: 0.2em;
}

@media(min-width: 400px) {
  .calendar-item img {
    width: 100%;
  }
}

.calendar-month-overview {
  width: 100%;
  display: block;
  margin: 0 auto;
}

@media(min-width: 400px) {
  .calendar-month-overview {
    width: 70%;
  }
}
