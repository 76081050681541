.image-grid {
  display: flex;
  flex-direction: column;
}

.image-grid-item {
  margin-bottom: 2em;

  img {
    width: 240px;
    margin-bottom: 1em;
  }
}

.member-title {
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 0.6em;
}

@media(min-width: 400px) {
  .image-grid {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  } 

  .image-grid-item {
    width: 45%;

    img {
      width: 140px;
    }
  }
}

@media(min-width: 640px) {
  .image-grid-item {
    width: 30%;
  }
}
