table {
  margin-bottom: 2em;
}

table:last-child {
  margin-bottom: 0;
}

tr {
  line-height: 1.4em;
}

tr > td:first-child {
  width: 150px;
}
