.shield-image {
  text-align: center;
  margin-bottom: 2em;
}

.shield-text {
  margin-bottom: 1em;
}

@media(min-width: 640px) {
  .shield {
    display: flex;
  }

  .shield-image {
    width: 40%;

    img {
      max-width: 100%;
    }
  }

  .shield-text {
    width: 45%;
    margin-top: 0.6em;
    margin-left: 2em;
  }
}
