.partner-list {
  display: flex;
  flex-direction: column;
}

.partner-description-description {
  margin: 0;
}

.partner-description {
  display: flex;
  flex-direction: column-reverse;

  img {
    align-self: baseline;
    width: 140px;
    min-width: 140px;
    margin-bottom: 1em;
  }
}

.partner-description-deal {
  font-style: italic;
}

@media(min-width: 960px) {
  .partner-list {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .partner {
    width: 48%;
  }

  .partner-description {
    flex-direction: row;
    justify-content: space-between;
  }

  .partner-description-description {
    margin-bottom: 1em;
  }

  .partner-description-text {
    padding-right: 1em;
  }
}
