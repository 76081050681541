@import "reset";
@import "box-reset";
@import "hamburger";

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: bold;
  src: url("/assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  color: #333;
  background-color: #e3e3e3;
  background-image: url("/assets/images/gravel.png");
}

.nav-wrapper {
  flex-shrink: 0;
  background-color: #1c0000;
}

header {
  background-color: #000;

  .banner {
    text-align: center;

    img {
      width: 100%;
      max-width: 1200px;
      max-height: 400px;
    }
  }
}

nav {
  padding: 1em 2em;
  font-size: 0.9em;
}

.home-shield-image {
  display: none;
}

.menu-list {
  display: none;
  flex-wrap: wrap;

  li {
    width: 100%;
    line-height: 2em;
  }
}

.menu-btn:checked {
  & ~ .menu-icon {
    margin-bottom: 2em;
  }

  & ~ .menu-list {
    display: flex;
  }
}

.submenu-title {
  color: #fff;
  opacity: 0.3;
}

.navigation .submenu-list {
  margin-left: 2em;
}

.navigation {
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fa2e1b;
    }
  }
}

.main {
  flex-grow: 1;
  padding: 2em;
  background: white;
}

h1, h2, h3 {
  margin-bottom: 1em;
  font-weight: bold;
}

h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 1.2em;
}

p {
  margin-bottom: 1em;
  line-height: 1.5em;
}

ul {
  list-style: disc;
  margin-left: 1.4em;
}

li {
  line-height: 1.4em;
}

strong {
  font-weight: bold;
}

blockquote {
  text-align: center;
  font-style: italic;
}

footer {
  padding: 2em 0;
  color: #fff;
  font-size: 0.8em;
  line-height: 1.5em;

  h3 {
    margin-bottom: 0.5em;
    color: #bb0f00;
  }
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
}

.footer-item {
  margin-bottom: 2em;
  width: 100%;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.copyright {
  margin-top: 2em;
  text-align: center;
  opacity: 0.3;
}

@media (min-width: 640px) {
  nav {
    padding: 1em 2em;
  }

  .home-shield {
    position: relative;
  }

  .home-shield-image {
    display: block;
    position: absolute;
    top: -35px;
    height: 100px;
  }

  .home-shield-text {
    margin-left: 100px;
  }

  .menu-icon {
    display: none;
  }

  .menu-list {
    display: flex;
    flex-wrap: wrap;

    & > li {
      width: auto;
      margin-right: 1.5em;
      line-height: auto;
    }
  }

  .submenu-title {
    opacity: 1;
    cursor: pointer;
    user-select: none;

    &:after {
      content: '\25bc';
      margin-left: 0.6em;
      font-size: 0.6em;
    }

    &:hover {
      color: #fa2e1b;
    }
  }

  .submenu-list {
    display: none;
  }

  .submenu-title:hover ~ .submenu-list, .submenu-list:hover {
    display: block;
    position: absolute;
    padding: 0 2em 0.5em 2em;
    background-color: #1c0000;
    z-index: 1;
  }

  .main {
    padding-top: 3em;
  }

  footer {
    line-height: 1.3em;
  }

  .footer-item {
    width: 30%;
    margin-bottom: 0;
    flex-grow: 1;
  }
}

@media (min-width: 960px) {
  .content {
    width: 960px;
    margin: 0 auto;
  }

  .menu-list > li {
    margin-right: 2em;
  }

  .main {
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    border-style: groove;
  }
}
