$navicon-bar-width: 2em;
$navicon-bar-height: 0.2em;
$navicon-bar-space-between: 0.5em;

.menu-btn {
  display: none;
}

.menu-btn:checked ~ .menu-icon .navicon {
  background-color: transparent;

  &:before {
    top: 0;
    transform: rotate(45deg);
  }

  &:after {
    top: 0;
    transform: rotate(-45deg);
  }
}

.menu-icon {
  display: inline-block;
  padding: 1em 0;
  cursor: pointer;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.navicon {
  display: block;
  width: $navicon-bar-width;
  height: $navicon-bar-height;
  position: relative;
  background-color: #fff;
  opacity: 0.3;

  &:before, &:after {
    content: '';
    width: $navicon-bar-width;
    height: $navicon-bar-height;
    position: absolute;
    background-color: #fff;
  }

  &:before {
    top: -$navicon-bar-space-between;
  }

  &:after {
    top: $navicon-bar-space-between;
  }
}
